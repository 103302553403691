import { vars } from "@pancakeswap/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import Floki from "./floki.png"

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
  <>
  <img src={Floki.src} height="45px"/>
  </>
  );
};

export default Logo;
