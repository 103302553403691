import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | FlokiDragon Swap',
  defaultTitle: 'FlokiDragon Swap',
  description:
    'Floki Dragon is the next moonshot memecoin on Binance Smart Chain.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@PancakeSwap',
    site: '@PancakeSwap',
  },
  openGraph: {
    title: 'Floki Dragon is the next moonshot memecoin on Binance Smart Chain.',
    description:
      'As we are a community driven project we also will add suggestions from the community.',
    images: [{ url: 'https://static.wixstatic.com/media/d38a32_545d6e95b363456797016c94385fd4f6~mv2.jpeg/v1/fill/w_1280,h_242,al_c,q_80,enc_auto/d38a32_545d6e95b363456797016c94385fd4f6~mv2.jpeg' }],
  },
}
