import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  TelegramIcon,
  CheckmarkCircleFillIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Limit'),
          href: '/limit-orders',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/decorations/3d-coin.png',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Website'),
      href: 'https://flokidragon.com',
      icon: CheckmarkCircleFillIcon,
      fillIcon: CheckmarkCircleFillIcon,
      items: [
      ]
    },
   
    {
      label: t('Telegram'),
      href: `https://t.me/flokidragon_bsc`,
      icon: TelegramIcon,
      fillIcon: TelegramIcon,
      supportChainIds: SUPPORT_ONLY_BSC,
      image: '/images/decorations/nft.png',
      items: [
      
      ],
    },
    {
      label: '',
      href: '#',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Whitepaper'),
          href: 'https://wixlabs-pdf-dev.appspot.com/assets/pdfjs/web/viewer.html?file=%2Fpdfproxy%3Finstance%3D9W_hWPrVnQZWZbAPnIz9rpA62ABFJXRiwKYqlg9_oRo.eyJpbnN0YW5jZUlkIjoiNTgwNzczN2YtNDkxMS00ZWVjLThkYTAtMTNlZjNkMWMzZWRhIiwiYXBwRGVmSWQiOiIxM2VlMTBhMy1lY2I5LTdlZmYtNDI5OC1kMmY5ZjM0YWNmMGQiLCJtZXRhU2l0ZUlkIjoiMzIzYzc0M2ItODdjYS00MDQ2LTg4MGUtOWVkMGI3NGQ2ODk5Iiwic2lnbkRhdGUiOiIyMDIzLTAzLTA1VDE1OjAzOjA1LjIwN1oiLCJkZW1vTW9kZSI6ZmFsc2UsImFpZCI6ImFlYjMyNjRkLTQ4OTAtNDI2NC1iZWU3LTlkZWViNzVlMjM2MCIsImJpVG9rZW4iOiI2YTNiMDc0NC1jZWRiLTBlYWEtMDVhZS04ZDNmOGE1MTU2NDMiLCJzaXRlT3duZXJJZCI6ImQzOGEzMmYxLTVjYzQtNDNkNy1iNDg4LWI4MjVkN2Y4ZWY4YSJ9%26compId%3Dcomp-le8ttkhz%26url%3Dhttps%3A%2F%2Fdocs.wixstatic.com%2Fugd%2Fd38a32_8b774ca007fe48918c483b491745d744.pdf&rng=1678028588128#page=1&links=true&originalFileName=Floki%20Dragon%20%20&locale=ja&allowDownload=true&allowPrinting=true&pagemode=none',
        },
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('Vote'),
          href: 'https://jptoken.info/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
     
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
